<nav class="bg-white/80  backdrop-blur-sm border border-gray-100 mx-6 mt-3 rounded-lg shadow-sm">
    <div class="flex items-center justify-between h-16 px-4">
        <div class="flex-shrink-0"></div>
        <div class="flex whitespace-nowrap justify-center flex-grow gap-2">
            @for (item of navItems; track item.label) {
                @if (item.canAccess && !item.isDropdown) {
                    <button
                            [routerLink]="item.path"
                            [class.active]="isActive(item.path!)"
                            class="px-4 py-2 rounded-lg text-gray-600 hover:text-gray-900 hover:bg-gray-50 transition-all duration-200 text-sm font-medium">
                        {{ item.label }}
                    </button>
                }
                @if (item.canAccess && item.isDropdown) {
                    <div class="relative">
                        <button
                                (click)="toggleDropdown($event, item.label)"
                                [class.active]="isDropdownOpen(item.label)"
                                class="px-4 py-2 rounded-lg text-gray-600 hover:text-gray-900 hover:bg-gray-50 transition-all duration-200 text-sm font-medium flex items-center gap-2">
                            {{ item.label }}
                            <svg
                                    class="w-4 h-4 transition-transform duration-200"
                                    [class.rotate-180]="isDropdownOpen(item.label)"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                            </svg>
                        </button>
                        @if (isDropdownOpen(item.label)) {
                            <div class="absolute whitespace-nowrap  w-fit mt-2 origin-top-right bg-white rounded-xl border border-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100 transition-all duration-200 z-50">
                                <div class="py-1">
                                    @for (subItem of item.children; track subItem.label) {
                                        @if (subItem.canAccess) {
                                            <a [routerLink]="subItem.path"
                                               (click)="activeDropdown = null"
                                               class="block px-4 py-2 text-sm text-gray-600 hover:bg-gray-50 hover:text-gray-900">
                                                {{ subItem.label }}
                                            </a>
                                        }
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            }
        </div>
        <div>
            <button (click)="logOut()"
                    class="log-button">
                Logout
            </button>
        </div>
    </div>
</nav>