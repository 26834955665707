import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {AuthService} from "../../services";
import {NavItem} from "./nav-item.entity";
import {Permission} from "../../types";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-top-nav',
    imports: [
        RouterLink
    ],
    templateUrl: './top-nav.component.html',
    standalone: true,
    styleUrl: './top-nav.component.scss'
})
export class TopNavComponent implements OnInit {
    constructor(
        private router: Router,
        private authService: AuthService,
        private elementRef: ElementRef
    ) {
    }

    showDropdown = false;
    activeDropdown: string | null = null;

    navItems: NavItem[] = [
        {path: 'developer', label: 'Developer 🚀', canAccess: false},
        {path: 'sales-dashboard', label: 'Sale Dashboard', canAccess: true},
        {
            label: 'Sales Order',
            canAccess: false,
            isDropdown: true,
            children: [
                {path: 'sales-order', label: 'Sales Order', canAccess: false},
                {path: 'sales-order-item', label: 'Sales Order Item', canAccess: false}
            ]
        },
        // {path: 'packing-list', label: 'packing-list', canAccess: false},
        {path: 'packaging', label: 'Packaging', canAccess: false},
        {
            label: 'Invoice',
            canAccess: false,
            isDropdown: true,
            children: [
                {path: 'invoice', label: 'View Invoice', canAccess: false},
                {path: 'invoice-create', label: 'Create Invoice', canAccess: false},
                {path: 'invoice/track', label: 'Track Invoice', canAccess: false}
            ]
        },
        {path: 'credit-note', label: 'Credit Note', canAccess: false},
        {path: 'ncr', label: 'NCR', canAccess: false},
        {path: 'production-update', label: 'Production Quantity', canAccess: false},
        {
            label: 'Report',
            path: 'reports',
            canAccess: false,
            isDropdown: true,
            children: [
                {path: 'reports/sales-report', label: 'Order Report', canAccess: false},
                {path: 'reports/invoice-report', label: 'Sales Report', canAccess: false}
            ]
        },
        {path: 'sales-map', label: 'Sales Map', canAccess: false},
        {path: 'customer', label: 'Customer', canAccess: false},
        {path: 'sales-order-new', label: 'Sales Order New', canAccess: false},

    ];

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.activeDropdown = null;
        }
    }

    @HostListener('document:keydown.escape')
    onEscapePress() {
        this.activeDropdown = null;
    }

    ngOnInit() {
        this.setNavigationPermissions();
    }

    private setNavigationPermissions() {
        if (!environment.production) {
            // Mocked permissions (only active in development) ~ Tharuka
            this.navItems = this.navItems.map(item => ({
                ...item,
                canAccess: true,
                children: item.children
                    ? item.children.map(child => ({ ...child, canAccess: true }))
                    : undefined
            }));
        } else {
            // Real permissions (active in production)
            const userAuthData = sessionStorage.getItem('user-auth');
            if (userAuthData) {
                try {
                    const userData = JSON.parse(userAuthData);
                    const permissions: Permission[] = userData.permissions || [];

                    this.navItems = this.navItems.map(item => {
                        if (item.children) {
                            const childrenWithAccess = item.children.map(child => ({
                                ...child,
                                canAccess: permissions.some(p =>
                                    p.path === child.path && p.canAccess ||
                                    p.path === `${item.path}/${child.path}` && p.canAccess
                                )
                            }));

                            return {
                                ...item,
                                canAccess: permissions.some(p => p.path === item.path && p.canAccess) ||
                                    childrenWithAccess.some(child => child.canAccess),
                                children: childrenWithAccess
                            };
                        }

                        return {
                            ...item,
                            canAccess: permissions.some(p => p.path === item.path && p.canAccess)
                        };
                    });
                } catch (error) {
                    console.error('Error parsing permissions:', error);
                }
            }
        }
    }

    toggleDropdown(event: Event, label: string) {
        event.stopPropagation();
        this.activeDropdown = this.activeDropdown === label ? null : label;
    }

    isDropdownOpen(label: string): boolean {
        return this.activeDropdown === label;
    }

    isActive(route: string): boolean {
        return this.router.url === '/' + route || this.router.url.startsWith('/' + route + '/');
    }

    logOut() {
        this.authService.userSignOut();
    }
}