import {Component, computed, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from './components/services/notification.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {DotLoadingServices} from "./core/services/dot-loading.Services";
import {initFlowbite} from "flowbite";
import {LoadingService} from "./core/components/loading/loading.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  isLoginPage: boolean = false;
  isLoading = computed(() => this.dotLoadingServices.isLoading());
  currentNotification = computed(() => this.notificationService.notification());
  private subscriptions: Subscription = new Subscription();

  title = 'Maxim-ERP';

  constructor(
      private loadingService: LoadingService,
      public dotLoadingServices: DotLoadingServices,
      public notificationService: NotificationService,
      private router: Router
  ) {}

  ngOnInit() {
    initFlowbite();

    this.subscriptions.add(
        this.loadingService.isLoading.subscribe((isLoading) => {
          this.dotLoadingServices.set(isLoading);
        })
    );

    this.router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isLoginPage = event.urlAfterRedirects.includes('/login');
    });
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  clearNotification() {
    const notification = this.currentNotification();
    if (notification) {
      this.notificationService.clearNotification(notification.id);
    }
  }
}