import {ResolveFn} from '@angular/router';
import {inject} from "@angular/core";
import {SalesDashboardService} from "../services";
import {catchError, of} from "rxjs";
import {SalesInfoDTO} from "../interface/sales-dashboard.entity";
import {APIResponse} from "../../../../../core";

export const salesDashboardResolver: ResolveFn<APIResponse<SalesInfoDTO> | null> = (route, state) => {
    const salesDashboardService = inject(SalesDashboardService);
    return salesDashboardService.getSalesInfo(true).pipe(
        catchError(error => {
            console.error("Error fetching data:", error);
            return of(null);
        })
    )
};
