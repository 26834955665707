import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./core";
import {salesDashboardResolver} from "./components/feature/New-Modules/sales-dashboard/resolver/sales-dashboard.resolver";

const baseRoutes: Routes = [
    {path: '', redirectTo: '/sales-dashboard', pathMatch: 'full'},

    {
        path: 'customer', loadChildren: () => import('./components').then(m => m.CustomerModule),
        data: {title: 'customer'},
        canActivate: [AuthGuard]
    },
    {
        path: 'developer',
        loadComponent: () => import('./components/index').then(com => com.DevoloperComponent),
    },
    {
        path: 'sales-dashboard',
        loadComponent: () => import('./components/index').then(com => com.SalesDashboardComponent),
        canActivate: [AuthGuard],
        resolve: {
            salesInfo: salesDashboardResolver
        }
    },
    {
        path: 'sales-map',
        loadComponent: () => import('./components/index').then(com => com.SalesMapComponent),
        canActivate: [AuthGuard],
    },
    {
        path: 'sales-order',
        loadComponent: () => import('./components/index').then(com => com.SalesOrderComponent),
        canActivate: [AuthGuard]
    },
    {
        path: 'sales-order-item',
        loadComponent: () => import('./components/index').then(com => com.SalesOrderItemComponent),
        canActivate: [AuthGuard]
    },
    {
        path: 'production-update',
        loadComponent: () => import('./components/index').then(com => com.ProductionUpdateComponent),
        canActivate: [AuthGuard]
    },
    {
        path: 'production-planing',
        loadComponent: () => import('./components/index').then(com => com.ProductionPlanningComponent),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports', loadChildren: () => import('./components/feature').then(m => m.ReportsModule),
        data: {title: 'Reports'},
        canActivate: [AuthGuard]
    },
    {
        path: 'invoice', loadChildren: () => import('./components/feature').then(m => m.InvoiceModule),
        data: {title: 'invoice'},
        canActivate: [AuthGuard]
    },
    {
        path: 'invoice-create',
        loadComponent: () => import('./components/index').then(com => com.InvoiceCreateComponent),
        canActivate: [AuthGuard]
    },
    {
        path: 'credit-note', loadChildren: () => import('./components').then(m => m.CreditNoteModule),
        data: {title: 'Credit Note'},
        canActivate: [AuthGuard]
    },
    {
        path: 'ncr', loadChildren: () => import('./components').then(m => m.NCRModule),
        data: {title: 'NCR'},
        canActivate: [AuthGuard]
    },
    {
        path: 'packaging', loadChildren: () => import('./components').then(m => m.PackagingModule),
        data: {title: 'Packaging'},
        canActivate: [AuthGuard]
    },
    {
        path: 'sales-order-new', loadChildren: () => import('./components').then(m => m.SalesOrderModule),
        data: {title: 'Sales Order New'},
        // canActivate: [AuthGuard]
    },
];

const routes: Routes = [
    ...baseRoutes,
    {
        path: 'login',
        loadComponent: () => import('./core/index').then(com => com.LoginComponent),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
